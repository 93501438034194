/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal';
import endpoints from '../constants/endpoints';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const styles = {
  nameStyle: {
    fontSize: '3em',
  },
  inlineChild: {
    display: 'inline-block',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImage: {
    width: '150px', // Adjust the size as needed
    height: '150px', // Adjust the size as needed
  },
  resumeButton: {
    margin: '20px', // Adjust margin as needed
    fontSize: '1.5em', // Adjust font size as needed
  },
};

function Home() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(endpoints.home, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);
  }, []);

  const handleHireMeClick = () => {
    const email = 'csaikishore145@gmail.com';
    const subject = 'Hiring Inquiry';
    const body = '';

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return data ? (
    <Fade>
      <div style={styles.mainContainer}>
        <h1 style={styles.nameStyle}>{data?.name}</h1>
        <div style={{ flexDirection: 'row' }}>
          <h2 style={styles.inlineChild}>I&apos;m&nbsp;</h2>
          <Typewriter
            options={{
              loop: true,
              autoStart: true,
              strings: data?.roles,
            }}
          />
        </div>
        <Button
          variant="primary"
          onClick={handleHireMeClick}
          style={styles.resumeButton}
        >
          Hire Me
        </Button>
        <Social />
      </div>
    </Fade>
  ) : <FallbackSpinner />;
}

export default Home;
